import RectangleShrinker from '../lib/rectangle_shrinker'
import { intersects, calculatePolygonDimensions, sortClockwise, distinct } from './array_utils'
import Utils from '../lib/utils'
import isInsidePolygon from 'robust-point-in-polygon'
import {getClippedDataUrlWorker, drawPath, drawImageOnCanvas} from '../service_workers/service-worker';
import ColorThief from "colorthief";
import BuildlqColors from '../lib/cropper/buildlq_colors'
import Polygon from '../lib/polygon'
import { initSlicesManagement } from '../lib/cropper/slices_management'
import { initCoordinatesTransformer } from '../lib/cropper/coordinates_transformer'
import { initRotationManagement } from '../lib/cropper/rotation_management'

const greinerHormann = require('greiner-hormann')

// Explain what are canvas utils
const initUtils = (context) => {
  context.canvasHorizontalMargin = 0
  context.canvasVerticalMargin   = 0
  context.blurSize = 0
  context.blurOffset = 0
  context.image = null
  context.parentImage = null
  context.gridMode = false

  context.clips = []
  context.deletedClips = []
  context.parentPolygon = []
  context.currentPath = []
  context.currentRotation = 0
  context.polygonalSlicingStartedFromLeft = false
  context.polygonalSlicingStartedFromRight = false

  // TODO: Make it dependent on a parameter, instead of checking presence of global values
  if (cropper){
    context.scenario = 'crop' // Split, Slice, and Highlight stage 
  } else if (typing_form) {
    context.scenario = 'type'
  } else {
    context.scenario = 'general'
  }

  initSlicesManagement(context)
  initCoordinatesTransformer(context)
  initRotationManagement(context)

  context.polygons = ({ onlyCurrentType = false, filteredClips = [] } = {}) => {
    let selectedClips = context.clips
    if (onlyCurrentType && cropper.isHighlightStage()){
      switch(cropper.entryType){
        case 'entry':
          selectedClips = selectedClips.filter((clip) => clip.entryType === 'entry')
          break;
        case 'subentry':
          selectedClips = selectedClips.filter((clip) => clip.entryType === 'subentry')
          break;
        case 'other':
          selectedClips = selectedClips.filter((clip) => clip.entryType === 'other' && clip.otherWordCategory == cropper.currentOtherWordCategory)
          break;
      }
    }
    // TODO: in future handle intersection through clipper-lib inplace of greiner-hormann
    if (filteredClips.length > 0){
      selectedClips = _.intersection(selectedClips, filteredClips)
    }

    return selectedClips.map((c) => c.polygon)
  }

  context.currentPathLastPoint = () => {
    return context.currentPath[context.currentPath.length - 1]
  }

  context.clearCurrentPath = () => {
    context.currentPath = []
  }

  // TODO: Add reason, what it does
  context.additionalYMargin = () => {
    let marginPixels = context.canvas.height * window.additionalYMarginRatio;
    return marginPixels;
  }

  context.clear = () => {
    const f = Math.min(1, context.globalZoomFactor || 1);
    let x = 0, y = 0;
    // TODO: Add reason
    let additionalYMargin = context.additionalYMargin();
    y = -additionalYMargin;

    if (f < 1) {
      x -= context.canvas.width / f;
      y -= context.canvas.height / f;
    }
    context.clearRect(x, y, context.canvas.width / f * 2, context.canvas.height / f * 2);
  }

  context.offsetX = () => {
    return context.canvas.getBoundingClientRect().left
  }

  context.offsetY = () => {
    return context.canvas.getBoundingClientRect().top
  }

  context.mousePoint = (event) => {
    return {
      x: parseInt(event.clientX - context.offsetX()),
      y: parseInt(event.clientY - context.offsetY())
    }
  }

  context.lockLine = (point1, point2, lock = 'horizontal') => {
    switch (lock) {
      case 'horizontal':
        point1.y = point2.y
        break
      case 'vertical':
        point1.x = point2.x
        break
    }
  }

  // This method is to make current line of polygon horizontal or vertical. This method is called with Shift key in this application
  context.lockDirection = (point1, point2) => {
    console.log(point1, point2);

    let yDisplacement = Math.abs(point1.y - point2.y);
    let xDisplacement = Math.abs(point1.x - point2.x);

    let direction = 'horizontal'
    if (yDisplacement > xDisplacement){
      direction = 'vertical'
    } else {
      direction = 'horizontal'
    }

    return direction;
  }

  context.distanceBetweenPoints = (firstPoint, secondPoint) => {
    const a = firstPoint.x - secondPoint.x
    const b = firstPoint.y - secondPoint.y
    return Math.sqrt(a * a + b * b)
  }

  context.lockToStart = (currentPoint, transformed) => {
    if (context.currentPath.length < 3)
      return false
    const startPoint = context.currentPath[0]
    const transformedStartPoint = context.transformFromOriginalCoordinates(startPoint)
    const transformedCurrentPoint = transformed ? currentPoint : context.transformFromOriginalCoordinates(currentPoint)
    if (context.distanceBetweenPoints(transformedStartPoint, transformedCurrentPoint) < 20) {
      currentPoint.x = transformed ? transformedStartPoint.x : startPoint.x
      currentPoint.y = transformed ? transformedStartPoint.y : startPoint.y
      return true
    }

    return false
  }

  // This method sets canvas/image size and margins for the image to be displayed on Canvas
  // moveCanvasToBottom is an optional/corner case argument, used only in Typing stages
  context.fitImageInCanvas = (image, container, { moveCanvasToBottom = undefined } = {}) => {
    const style     = window.getComputedStyle(container, null)
    const maxHeight = container.offsetHeight - parseInt(style.paddingTop) - parseInt(style.paddingBottom)
    const maxWidth  = container.offsetWidth - parseInt(style.paddingLeft) - parseInt(style.paddingRight)
    let imageDimensions = context.getRotatedDimensions(image, context.currentRotation)
    
    let imageHeight = null, imageWidth = null, canvasHorizontalMargin = null, canvasVerticalMargin = null;
    // if (imageDimensions.height < imageDimensions.width && imageDimensions.height / imageDimensions.width * maxWidth < maxHeight) {
    if (imageDimensions.height / imageDimensions.width * maxWidth < maxHeight) {
      imageWidth = context.canvas.width = maxWidth
      canvasHorizontalMargin = 0
      
      imageHeight = imageDimensions.height / imageDimensions.width * context.canvas.width
      
      if (moveCanvasToBottom){
        canvasVerticalMargin  = (maxHeight - imageHeight) // to remove bottom margin and put region image close to text field, use the below code line to place region image at center
      } else {
        // This is the default case
        canvasVerticalMargin  = (maxHeight - imageHeight) / 2
      }
      context.canvas.height = imageHeight + 2 * canvasVerticalMargin
    } else {
      imageHeight = context.canvas.height = maxHeight
      imageWidth  = imageDimensions.width / imageDimensions.height * context.canvas.height

      canvasHorizontalMargin = (maxWidth - imageWidth) / 2
      context.canvas.width   = imageWidth + 2 * canvasHorizontalMargin
    }

    return {
      canvasHorizontalMargin: canvasHorizontalMargin,
      canvasVerticalMargin:   canvasVerticalMargin,
      imageWidth:             imageWidth,
      imageHeight:            imageHeight
    }
  }

  context.canvasHorizontalMarginActual = () => {
    return context.canvasHorizontalMargin + context.blurOffset
  }

  context.canvasVerticalMarginActual = () => {
    return context.canvasVerticalMargin + context.blurOffset
  }

  context.setBackgroundColor = () => {
    context.fillStyle = 'rgb(128, 128, 128)'
    context.fillRect(0, 0, context.canvasHorizontalMargin, context.canvas.height)
    context.fillRect(context.canvas.width - context.canvasHorizontalMargin, 0, context.canvasHorizontalMargin, context.canvas.height)
  }

  // In the new mechanism of displaying polygons, we have some nested levels of polygons(page, column, region) but we use a single image(page's image)
  // So, for nested polygons, we only want to show area of the selected polygon from the image, excluding any of its parent area
  // We show area in the shape of a rectangle, so what we do is, that we make the outside region of the current polygon blur by this method
  // Ticket: https://trello.com/c/Umu93lPn/356-parts-which-are-not-included-by-the-polygon-are-not-to-be-shown-typeo
  context.initBlur = (extraWidth = 1) => {
    if (!context.parentPolygon.length)
      return
    const contextualPolygon = sortClockwise([
      {
        x: context.canvasHorizontalMargin - extraWidth,
        y: 0
      },
      {
        x: context.canvas.width - context.canvasHorizontalMargin + extraWidth,
        y: 0
      },
      {
        x: context.canvas.width - context.canvasHorizontalMargin + extraWidth,
        y: context.canvas.height
      },
      {
        x: context.canvasHorizontalMargin - extraWidth,
        y: context.canvas.height
      }
    ])
    let scaledPolygon = null;
    // TODO: Add reason why different for both
    if (!cropper){
      scaledPolygon = context.unscaledPath(context.parentPolygons[1])
      // cropper is present for split, slice and highlight stages AND cropper is not present for type-o and type-l stages
    } else {
      scaledPolygon = context.unscaledPath(context.parentPolygon)
    }


    // Mask Polygon color
    const maskColor = context.calculateMaskColor();
    // Mask Polygon polygon, must be clockwise
    context.drawPath({ points: contextualPolygon, closePath: true, lineWidth: 0, strokeStyle: maskColor })
    // Mask Polygon, which hides other layer of polygons
    context.fillStyle = maskColor
    context.fill()
    // Mask polygon, must be counter-clockwise
    context.drawPath({ points: scaledPolygon, closePath: false, lineWidth: 0, strokeStyle: maskColor })
  }

  context.calculateMaskColor = () => {
    if (context.scenario != 'type'){
      var colorThief = new ColorThief();
      let imageMajorColor = colorThief.getColor(context.image);
      let imageColorRBD = context.rgbColor(imageMajorColor)
      
      // return imageColorRBD;
      // To match the background
      return 'rgb(128, 128, 128)'
    } else {
      return 'rgb(255, 255, 255)'
    }
  }

  context.initHover = (hoverContext) => {
    hoverContext.canvas.width = context.canvas.width
    hoverContext.canvas.height = context.canvas.height
    hoverContext.clear()
    hoverContext.fillStyle = 'transparent'
    hoverContext.fillRect(0, 0, hoverContext.canvas.width, hoverContext.canvas.height)
  }

  context.drawPath = ({ points = [], closePath = false, lineWidth = null, strokeStyle = 'red' }) => {
    return drawPath({ context, points, closePath, lineWidth, strokeStyle });
  }

  context.drawGrid = () => {
    const gridColor = 'rgba(145, 162, 139, 1)'
    const offset = 20
    let x = offset, y = offset
    while (x < context.canvas.width) {
      context.drawPath({points: [{ x: x, y: 0 }, { x: x, y: context.canvas.height }], closePath: false, lineWidth: null, strokeStyle: gridColor})
      context.stroke()
      x += offset
    }
    while (y < context.canvas.height) {
      context.drawPath({points: [{ x: 0, y: y }, { x: context.canvas.width, y: y }], closePath: false, lineWidth: null, strokeStyle: gridColor})
      context.stroke()
      y += offset
    }
  }

  context.drawRectangle = (lastPoint, rectangleStartPoint) => {
    const width = lastPoint.x - rectangleStartPoint.x
    const height = lastPoint.y - rectangleStartPoint.y

    context.clearCurrentPath()

    const rectanglePoints = [
      rectangleStartPoint,
      {
        x: rectangleStartPoint.x,
        y: rectangleStartPoint.y + height
      },
      {
        x: rectangleStartPoint.x + width,
        y: rectangleStartPoint.y + height
      },
      {
        x: rectangleStartPoint.x + width,
        y: rectangleStartPoint.y
      }
    ]
    context.currentPath.push(...rectanglePoints)
  }

  context.contextualRect = () => {
    const { minX, minY, width, height } = calculatePolygonDimensions(context.parentPolygon)
    const contextualPath = [
      {
        x: minX - context.blurSize,
        y: minY - context.blurSize
      },
      {
        x: minX - context.blurSize,
        y: minY + height + context.blurSize
      },
      {
        x: minX + width + context.blurSize,
        y: minY + height + context.blurSize
      },
      {
        x: minX + width + context.blurSize,
        y: minY - context.blurSize
      },
    ]

    return context.getClippedDataUrl(contextualPath, context.parentImage)
  }

  // TODO: Explain and differentiate all scenarios
  // path: is used in slice stage - Slices has only a path
  // slice_uniq_id: is used to link slice and clips
  context.pushClippedData = ({ id = undefined, path = false, index = false, entryType = 'none', image = null, addOffset = true, slice_uniq_id = null, isNewClip = true }) => {
    if (!path)
      path = context.currentPath
    if (!image)
      image = context.parentImage

    const pathYs = path.map((p) => p.y)
    const startingIndex = pathYs.indexOf(Math.min.apply(Math, pathYs))
    path = distinct(
      path.slice(startingIndex, path.length).concat(
        path.slice(0, startingIndex)
      ),
      ['x', 'y']
    )
    // TODO: add reason for this
    if (path[1].y > path[path.length - 1].y)
      path.reverse()

    if (addOffset)
      path = path.map((point) => {
        return {
          x: point.x + context.blurOffset,
          y: point.y + context.blurOffset,
        }
      })
    // scale path due to image ratio
    const scaledPath = context.scaledPath(path)
    // "CLIP_CREATION" javascript/runtime
    // markedForLinkingDuplicate
    const clip = {
      id: id,
      polygon: path,
      scaledPolygon: scaledPath,
      entryType: entryType,
      slice_uniq_id: slice_uniq_id, // Used in slice stage
      data: context.getClippedDataUrl(scaledPath, image),
    }

    if (cropper.isHighlightStage()){
      let otherWordCategoryRadioButton = document.querySelector('.js-other-word-category-with-name:checked')
      if (otherWordCategoryRadioButton) {
        clip["otherWordCategory"] = otherWordCategoryRadioButton.dataset['otherWordCategoryId'];
      }
    }
    // if index is present, it means updating existing clip
    // else new clip is being added
    if (index === false) {
      context.clips.push(clip)
      if(cropper.isHighlightStage() || cropper.isSplitStage()){
        cropper.assignNumberNewClip(clip);
      }
    } else {
      clip.id = context.clips[index].id
      clip.entryType = context.clips[index].entryType
      clip.otherWordCategory = context.clips[index].otherWordCategory
      clip.number = context.clips[index].number
      clip.subNumber = context.clips[index].subNumber
      clip.inParts = context.clips[index].inParts

      context.clips[index] = clip
    }
    
    if(isNewClip) {
      context.shrinkRectangleIfRequired(clip, image);
    }      
  }

  context.shrinkRectangleIfRequired = (clip, image) => {
    if (cropper.isHighlightStage()){
      // Shrinking Rectangle for highlight stage
      if (clip.polygon.length == 4){
        context.clearCurrentPath()
        cropper.pushState(false, true);

        let shrinker = new RectangleShrinker(context, clip.polygon)
        shrinker.shrinkRectangle()

        let scaledPath = context.scaledPath(clip.polygon)
        clip.scaledPolygon = scaledPath
        clip.data = context.getClippedDataUrl(scaledPath, image)
      }
    }
  }

  context.getClippedDataUrl = (path, image) => {
    if (!image) {
      image = context.image;
    }
    const { width, height } = context.getRotatedDimensions(image, context.currentRotation);
    return getClippedDataUrlWorker(path, image, context.currentRotation, width, height);
  }

  context.isInsidePolygon = (point, polygon) => {
    return isInsidePolygon(polygon.map(pp => [pp.x, pp.y]), [point.x, point.y]) < 0
  }

  context.findPolygonByPointInside = (point, { onlyCurrentClipType = false } = {}) => {
    const polygons = context.polygons()
    const polygonIndex = polygons.findIndex((polygon) => context.isInsidePolygon(point, polygon))
    if (polygonIndex >= 0)
      return [polygons[polygonIndex], polygonIndex]
    return [false, false]
  }

  function polygonArea(vertices) {
    var total = 0;

    for (var i = 0, l = vertices.length; i < l; i++) {
      var addX = vertices[i].x;
      var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
      var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
      var subY = vertices[i].y;

      total += (addX * addY * 0.5);
      total -= (subX * subY * 0.5);
    }

    return Math.abs(total);
  }

  function clipHeight(clip) {
    let points = clip.polygon;
    let y_coordinates = points.map((point) => {
      return point.y
    })
    let minY = Math.min(...y_coordinates)
    let maxY = Math.max(...y_coordinates)

    let clipHeight = maxY - minY;
    return clipHeight;
  }

  context.findIntersectingPolygons = ({ filteredClips = [] } = {}) => {
    let path            = context.currentPath
    let polygonAndIndex = [false, false]
    
    // Use clips instead of polygons
    let intersectingPolygons = context
    .polygons({ onlyCurrentType: false, filteredClips: filteredClips })
    .map((polygon, polygonIndex, list, intersection = greinerHormann.intersection(polygon, path)) =>
      [polygon, polygonIndex, (intersection?.length === 1) && polygonArea(intersection[0])])
    .filter(tuple => !!tuple[2])

    return intersectingPolygons;
  }

  // This method is used in merging polygons. If user wants to extend a polygon and draws a new polygon for it on the canvas, system will select the polygon with the maximum area covering polygon
  // This method returns the polygon and its index in clips array AND currently this method search for onlyCurrentType clips
  context.findPolygonByLargestIntersection = ({ filteredClips = [] } = {}) => {
    let path = context.currentPath
    let polygonAndIndex = [false, false]
    
    // Use clips instead of polygons
    let selected = context
    .findIntersectingPolygons({ filteredClips: filteredClips })
    .sort((a, b) => b[2] - a[2])
    .at(0)

    if (selected){
      let polygon = selected[0]
      let selectedIndex = -1;
      context.clips.forEach((clip, index) => {
        if (selectedIndex != -1)
          return; // Move out of this iteration

        if (filteredClips.length > 0){
          if (filteredClips.includes(clip)){
            selectedIndex = index;
          }
        } else {
          if(clip.polygon == polygon){
            selectedIndex = index;
          }
        }
      })
      polygonAndIndex[0] = polygon;
      polygonAndIndex[1] = selectedIndex;
    }

    return polygonAndIndex;
  }

  // removes polygon/click when cursor points to a particular clip 
  context.removePolygon = (point) => {
    const [polygon, polygonIndex] = context.findPolygonByPointInside(point)
    if (polygonIndex === false)
      return false

    if (polygonIndex >= 0) {
      let clipToRemove = context.clips[polygonIndex]
      cropper.removeClip(clipToRemove)

      return true
    }
    return false
  }

  // remove clip 
  context.removeClip = (clip) => {
    var clips = context.clips
    var polygonIndex = clips.indexOf(clip)
    if (polygonIndex == -1) {
      return
    } else {
      clips.splice(polygonIndex, 1)
    }

    if(cropper.isHighlightStage() || cropper.isSplitStage()){
      cropper.handleClipNumberForDeletion(clip);
    }

    if (clip.id)
      context.deletedClips.push(clip)
  }

  // Removes a slicing path from a point clicked on screen
  context.removePath   = (point) => {
    // Sorting slicingPaths START
    const getDistance = (path) => {
      let point = { x: 0, y: 0 };
      let closestLine = context.extractLines([...path]).sort((line1, line2) => {
        return context.distanceFromPointToLine(point, line1) - context.distanceFromPointToLine(point, line2)
      })[0]
      return context.distanceFromPointToLine(point, closestLine)
    }
    context.slicingPaths = context.slicingPaths.sort((slice_a, slice_b) => {
      return getDistance(slice_a.path) - getDistance(slice_a.path)
    })
    // Sorting slicingPaths END

    const { path, closestLine, pathIndex } = context.borderingPath(point)
    if (pathIndex === false)
      return false
    if (pathIndex >= 0){
      let nextSlice = context.slicingPaths[pathIndex + 1]
      // To avoid deletion of last Slice. nextSlice will not be available for last slice
      if (nextSlice) {
        const removedPolygons = context.slicingPaths.splice(pathIndex, 1)
        if (removedPolygons[0].id){
          context.deletedClips.push(removedPolygons[0])
          // Delete next clip as well to avoid overlapping slices. It will be created as new item 
          context.deletedClips.push({ id: nextSlice.id })
          nextSlice.id = null;
        }

        return true
      }
      return false
    }
  }

  context.distanceFromPointToLine = (point, line) => {
    const v = line[0]
    const w = line[1]
    const dist = (a, b) => Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)
    const l = dist(v, w)
    if (l == 0)
      return dist(point, v)
    let t = ((point.x - v.x) * (w.x - v.x) + (point.y - v.y) * (w.y - v.y)) / l;
    t = Math.max(0, Math.min(1, t))
    return Math.sqrt(
      dist(
        point,
        { x: v.x + t * (w.x - v.x),
          y: v.y + t * (w.y - v.y) }
      )
    )
  }

  // Find the slicing line for removal - when it is right clicked from a point
  context.borderingPath = (point, margin = 20) => {
    let closestLine = false
    const getDistance = (path) => {
      closestLine = context.extractLines([...path]).sort((line1, line2) => {
        return context.distanceFromPointToLine(point, line1) - context.distanceFromPointToLine(point, line2)
      })[0]
      return context.distanceFromPointToLine(point, closestLine)
    }
    const path = [...context.slicingPaths].sort((a, b) => {
      return getDistance(a.path) - getDistance(b.path)
    })[0].path

    if (!path || getDistance(path) > margin)
      return { path: false, closestLine, pathIndex: -1 }
    return { path, closestLine, pathIndex: context.slicingPaths.findIndex(sp => JSON.stringify(sp.path) === JSON.stringify(path)) }
  }

  context.extractLines = (path) => {
    return path.map((point, index) => {
      if (index < path.length - 1)
        return [point, path[index + 1]]
      return false
    }).filter(lines => lines != false)
  }

  context.drawPolygons = ({ showEntriesNumbering }) => {
    context.clips.forEach((clip, i) => {
      let fillStyle = BuildlqColors.clipColor(clip, i)
      context.fillStyle = fillStyle;
      context.drawPath({ points: clip.polygon, closePath: true });
      context.fill();

      if(clip.markedForLinkingDuplicate) {
        context.strokeStyle = 'rgba(0,100,200, 1)'
        context.stroke();
      } else if (clip.markedForLinking) {
        // Entry color by default
        context.stroke();
      } else if (clip.markedToEditPolygon) {
        context.strokeStyle = 'rgba(0, 0, 200, 1.0)'
        context.stroke()
      }
      // if clip is clicked from reorder_regions menu, then system shows it in different color for user
      if(clip.clicked) {
        context.strokeStyle = 'rgba(20, 20, 20, 1.0)'
        context.stroke();
      }

      if (showEntriesNumbering) context.drawClipNumber(clip);
    })
  }

  // Highlight
  context.averageClipHeight = () => {
    let sum = context.clips.reduce((sum, clip) => sum + clipHeight(clip), 0);
    let averageHeight = sum / (context.clips.length || 1 );

    return averageHeight;
  }

  // Highlight - Split
  context.clipNumberFontSizeCanvasNumber = () => {
    let ratio = 1.00;
    if (cropper.isSplitStage())
      ratio = 1.00 / 45;
    else if (cropper.isHighlightStage())
      ratio = 1.00 / 8;

    // TODO: Performance issue, calculating again and again the sum of all clip's height for each clip
    let averageHeight = context.averageClipHeight()

    // base height for scaling, this height is as a constant, it is the height of large screen in portrait monitor
    // Ahmet's big screen resolution is: 3840 X 2160 
    let baseHeight   = 2160;
    let canvasHeight = context.canvas.height;

    // Making brackets of font size instead of directly proportional, 50 in our case right now
    let fontBracket = 25;
    let fontHeight  = (Math.ceil(averageHeight / fontBracket)) * fontBracket;

    // to adjust font size based on average height of clips
    let clipsAverageHeightsAdjustment = 1.0 + (baseHeight - averageHeight) / baseHeight * 0.5; // Adjust the 0.5 to control the sensitivity of the scaling
   
    // to adjust font size based on the canvas height
    let heightScalingFactor = Math.pow(canvasHeight / baseHeight, 0.5);
    heightScalingFactor     = Math.max(heightScalingFactor, 1);

    fontHeight = fontHeight * ratio;

    if (cropper.isSplitStage()) {
      fontHeight = fontHeight * heightScalingFactor * clipsAverageHeightsAdjustment;
    }

    return (fontHeight || 1);
  }

  // Highlight
  context.clipNumberFontSizeCanvas = () => {
    return (context.clipNumberFontSizeCanvasNumber()) + 'px Roboto';
  }

  context.displayNumber = (clip) => {
    let number = ''
    if (cropper.isSplitStage()){
      number = clip.number
    } else if (cropper.isHighlightStage()) {
      if(clip.inParts && clip.subNumber){
        number = clip.number + clip.subNumber;
      } else {
        number = clip.number;
      }

      // If two clips are present with exact overlapping polygon, display their numbers joined
      let samePointsClips = []
      context.clips.forEach((existingClip) => {
        if (JSON.stringify(clip.polygon) == JSON.stringify(existingClip.polygon)){
          samePointsClips.push(existingClip)
        }
      });
      
      if (samePointsClips.length <= 1)
        return number;

      // Overlapping clips case
      // Showing number only for the smallest number clip
      number = ''
      samePointsClips = cropper.sortClipsIncludingSubNumbers(samePointsClips)
      if (clip.number == samePointsClips[0].number){
        samePointsClips.forEach((clip) => {
          if(clip.inParts && clip.subNumber){
            if (number){
              number = `${number}|${clip.number}${clip.subNumber}`;
            } else {
              number = `${clip.number}${clip.subNumber}`;
            }
          } else {
            if (number){
              number = `${number}|${clip.number}`;
            } else {
              number = `${clip.number}`;
            }
          }
        })
      } else {
        number = '';
      }
      // No need to add Name of other word
      // number = appendOtherWordCategory(number, clip)
    }

    return number
  }

  function drawBadge(x, y, width, height){
    context.fillStyle   = "#1CBB8C"; // for badge color 
    context.strokeStyle = "#0026FF"; // for badge border 
    context.lineWidth   = 1; // badge border width
    context.beginPath();
    let cornerRadius        = 4; //for rounded corners
    let adjustedHeight      = height - 5; // to make number appear at center of badge, technically reducing the bottom padding here
    let adjustedRightWidth  = width - 2;  // reducing width to reduce right padding here
    context.moveTo(x - width / 2 + cornerRadius, y - height / 2); // top-left corner
    context.lineTo(x + adjustedRightWidth / 2 - cornerRadius, y - height / 2); // top-right corner
    context.quadraticCurveTo(x + adjustedRightWidth / 2, y - height / 2, x + adjustedRightWidth / 2, y - height / 2 + cornerRadius); // top-right rounded corner
    context.lineTo(x + adjustedRightWidth / 2, y + adjustedHeight / 2 - cornerRadius); // right side
    context.quadraticCurveTo(x + adjustedRightWidth / 2, y + adjustedHeight / 2, x + adjustedRightWidth / 2 - cornerRadius, y + adjustedHeight / 2); // bottom-right rounded corner
    context.lineTo(x - width / 2 + cornerRadius, y + adjustedHeight / 2); // bottom side
    context.quadraticCurveTo(x - width / 2, y + adjustedHeight / 2, x - width / 2, y + adjustedHeight / 2 - cornerRadius); // bottom-left rounded corner
    context.lineTo(x - width / 2, y - height / 2 + cornerRadius); // left side
    context.quadraticCurveTo(x - width / 2, y - height / 2, x - width / 2 + cornerRadius, y - height / 2); // top-left rounded corner
    context.closePath();
    context.fill();
    context.stroke(); // for border
  };

  // Highlight - Split
  context.drawClipNumber = (clip) => {
    let displayNumber = context.displayNumber(clip);
    let pointToDisplay = highestRightCoordinate(clip.polygon)
    context.font = context.clipNumberFontSizeCanvas();
    
    if (cropper.isSplitStage()){
      let fontSize = context.clipNumberFontSizeCanvasNumber();

      context.textAlign    = "center";
      context.textBaseline = "middle";

      // setting text width and height for the badge
      let textWidth  = context.measureText(displayNumber).width;
      let textHeight = fontSize; // approximate height based on font size

      // scale based on current canvas width, here 2500 is a constant height of large screen (portrait shaped monitor screen)
      const scaleFactor = context.canvas.width / 2500;
      let badgePadding  = 4 * scaleFactor; //for padding around the number      

      let badgeWidth   = textWidth + badgePadding;
      let badgeHeight  = textHeight + badgePadding;

      // setting coordinates to display number
      let displayNumberX = pointToDisplay.x + badgeWidth / 2;
      let displayNumberY = pointToDisplay.y - badgeHeight / 2;

      // draw badge (rounded rectangle)
      drawBadge(displayNumberX, displayNumberY, badgeWidth, badgeHeight)

      context.fillStyle = "white"; // its placement here is crucial, it should be after 'drawBadge' method // white color for displaying number in white on split stage
      context.fillText(displayNumber, displayNumberX, displayNumberY);

    } else if (cropper.isHighlightStage()) {  
      context.fillStyle  = "red"; // red color for highlight stage
      let displayNumberX = pointToDisplay.x
      let displayNumberY = pointToDisplay.y + context.clipNumberFontSizeCanvasNumber()

      context.fillText(displayNumber, displayNumberX, displayNumberY);
    }    
  }

  function appendOtherWordCategory(number, clip){
    if(clip.entryType == 'other'){
      number = `${number} ${cropper.otherWordCategoryOptions[clip.otherWordCategory] || ''}`
    }

    return number
  }

  //Todo: Set number on highestRightCoordinate
  function highestRightCoordinate(points) {
    let encapsulatingRentangle = calculateEncapsulatingRectangle(points);
    return calculateRecursiveTopRightCoordinate(points, encapsulatingRentangle);
  }

  function calculateRecursiveTopRightCoordinate(points, encapsulatingRentangle) {
    let filteredPoints = filterQuadrantPoints(points, 1)
    if (filteredPoints.length == 1){
      return filteredPoints[0];
    } else if (filteredPoints.length > 1){
      let quadrantEncapsulatingRentangle = calculateEncapsulatingRectangle(filteredPoints);
      return calculateRecursiveTopRightCoordinate(filteredPoints, quadrantEncapsulatingRentangle);
    } else {
      let filteredPoints = filterQuadrantPoints(points, 2)
      let quadrantEncapsulatingRentangle = calculateEncapsulatingRectangle(filteredPoints);
      return calculateRecursiveTopRightCoordinate(filteredPoints, quadrantEncapsulatingRentangle);
    }
  }

  function filterQuadrantPoints(points, quadrantNumber){
    let centerOfRectangle = findMidPoint(points);
    let calculatedRectangle = calculateEncapsulatingRectangle(points);
    let filteredPoints = [];

    if (quadrantNumber == 1){
      let minX = centerOfRectangle.x;
      let maxX = calculatedRectangle[1].x;
      let minY = calculatedRectangle[1].y;
      let maxY = centerOfRectangle.y;
      let rectangle = [ { x: minX, y: minY }, { x: maxX, y: minY }, { x: minX, y: maxY }, { x: maxX, y: maxY } ];

      filteredPoints =  filterPointsInRectangle(points, rectangle);
    } else {
      // for 2 only
      let minX = centerOfRectangle.x;
      let maxX = calculatedRectangle[3].x;
      let minY = centerOfRectangle.y;
      let maxY = calculatedRectangle[3].y;
      let rectangle = [ { x: minX, y: minY }, { x: maxX, y: minY }, { x: minX, y: maxY }, { x: maxX, y: maxY } ];

      filteredPoints =  filterPointsInRectangle(points, rectangle);
    }

    return filteredPoints;
  }

  function calculateEncapsulatingRectangle(points){
    let result = calculateRectangleMinMax(points);
    return [ { x: result.minX, y: result.minY }, { x: result.maxX, y: result.minY }, { x: result.minX, y: result.maxY }, { x: result.maxX, y: result.maxY } ];
  }

  function findMidPoint(points) {
    let result = calculateRectangleMinMax(points);
    return {x: (result.minX + result.maxX) / 2, y: (result.minY + result.maxY) / 2};
  }

  function filterPointsInRectangle(points, rectangle){
    return points.filter(point => isPointInRectangle(point, rectangle))
  }

  function isPointInRectangle(point, rectangle){
    let minX = rectangle[0].x;
    let minY = rectangle[0].y;
    let maxX = rectangle[3].x;
    let maxY = rectangle[3].y;

    return (point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY );
  }

  function calculateRectangleMinMax(points){
    let minX, maxX, minY, maxY;
    for (let i = 0; i < points.length; i++) {
      minX = (points[i].x < minX || minX == null) ? points[i].x : minX;
      maxX = (points[i].x > maxX || maxX == null) ? points[i].x : maxX;
      minY = (points[i].y < minY || minY == null) ? points[i].y : minY;
      maxY = (points[i].y > maxY || maxY == null) ? points[i].y : maxY;
    }

    return { minX: minX, maxX: maxX, minY: minY, maxY: maxY }
  }

  context.drawSlices = () => {
    context.slicingPaths.forEach((sp) => {
      context.drawPath({ points: sp.path })
      context.stroke()
    })
  }

  context.drawImageOnCanvas = (image, { horizontalOffsetCanvas, verticalOffsetCanvas, horizontalWidthCanvas, verticalHeightCanvas, offsetX, offsetY }) => {
    return drawImageOnCanvas(context, image, { horizontalOffsetCanvas: horizontalOffsetCanvas, verticalOffsetCanvas: verticalOffsetCanvas, horizontalWidthCanvas: horizontalWidthCanvas, verticalHeightCanvas: verticalHeightCanvas, offsetX: offsetX, offsetY: offsetY });
  }

  function setSlicingStartSide(point, imageLeftBoundary, imageRightBoundary) {
    context.polygonalSlicingStartedFromLeft  = point.x <= imageLeftBoundary;
    context.polygonalSlicingStartedFromRight = point.x >= imageRightBoundary;
  }

  context.validatePoint = (point, stage, startsFromBorder = false) => {
    const imagePolygonWidth = new Polygon(context.unscaledPath(context.parentPolygon)).width();
    const imageLeftBoundary  = context.canvasHorizontalMarginActual();
    const imageRightBoundary = imageLeftBoundary + imagePolygonWidth;
    
    // check all Conditions for Split and Highlight stages
    if (stage === 'split' || stage === 'highlight') {
      if (context.currentPath.length < 1 && point.x >= imageLeftBoundary) {
        return false;
      }
      if (context.currentPath.length >= 1 && point.x <= imageLeftBoundary) {
        return false;
      }
      if (point.x < imageLeftBoundary || point.x > context.canvas.width - imageLeftBoundary) {
        return false;
      }
    }

    // Conditionally check conditions for Slice stage because on slice stage we do not allow to click on the right side of page on canvas
    if (stage === 'slice') {
      if (startsFromBorder) {
        if (context.currentPath.length < 1) {
          setSlicingStartSide(point, imageLeftBoundary, imageRightBoundary)
          // Restrict clicking if the first point clicked is inside the image polygon area
          if (point.x >= imageLeftBoundary && point.x <= imageRightBoundary) {
            return false;
          }
        } 
        else if (context.currentPath.length >= 1) {
          // Restrict clicks based on the side from which slicing started, if started from left of image then can't end on left side of image and vice a versa
          const isLeftViolation = point.x <= imageLeftBoundary && context.polygonalSlicingStartedFromLeft;
          const isRightViolation = point.x >= imageRightBoundary && context.polygonalSlicingStartedFromRight;
          if (isLeftViolation || isRightViolation) {
            return false;
          }
        }      
      }
    }

    // doesn't intersect with current paths
    const hasIntersectionWithCurrentPath =
      context.currentPath.length > 0 && context.currentPath.some((polygonPoint, i) => {
        return (i < context.currentPath.length - 1) && intersects(
          [context.currentPathLastPoint(), point], [polygonPoint, context.currentPath[i + 1]]
        )
      })
    return !hasIntersectionWithCurrentPath;
  }

  context.fitPoint = (point) => {
    // Starts from border
    point.x = Math.max(
      Math.min(point.x, context.canvas.width),
      context.canvasHorizontalMarginActual()
    );
    // Checking Y coordinate is inside the canvas
    point.y = Math.max(
      Math.min(point.y, context.canvas.height),
      context.canvasVerticalMarginActual()
    );
    return point;
  }

  context.isHoverPointInCanvas = (currentHoverPoint) => {
    return (currentHoverPoint.x > 0 && currentHoverPoint.y > 0);
  }

  context.findClickedClip = (currentHoverPoint) => {
    let selectedEntry = context.clips.find((clip) => {
      let closestToOriginCoordiate = closestToOriginPoint(clip);
      let farthestFromOriginCoordiate = farthestFromOriginPoint(clip);

      return context.isInsidePolygon(currentHoverPoint, clip.polygon);
    });

    return selectedEntry;
  }

  context.rgbColor = (colorArray) => {
    return "#" + (colorArray[0]).toString(16) + (colorArray[1]).toString(16) + (colorArray[2]).toString(16);
  }
}

// TODO: DRY
const closestToOriginPoint = (clip) => {
  let closestCoordinate = clip.polygon[0];
  clip.polygon.forEach((polygonCoordinates) => {
    if(polygonCoordinates.y < closestCoordinate.y){
      closestCoordinate = polygonCoordinates;
    } else if (polygonCoordinates.y == closestCoordinate.y && polygonCoordinates.x > closestCoordinate.x) {
      closestCoordinate = polygonCoordinates;
    }
  });

  return closestCoordinate;
}

const farthestFromOriginPoint = (clip) => {
  let farthestCoordinate = clip.polygon[0];
  clip.polygon.forEach((polygonCoordinates) => {
    if(polygonCoordinates.y > farthestCoordinate.y){
      farthestCoordinate = polygonCoordinates;
    } else if (polygonCoordinates.y == farthestCoordinate.y && polygonCoordinates.x < farthestCoordinate.x) {
      farthestCoordinate = polygonCoordinates;
    }
  });

  return farthestCoordinate;
}

export {
  initUtils
}
