// dictionary_general_controller.js
import ApplicationController from "./application_controller";
import API from '../lib/api'

// General stimulus controller added for generic events
export default class GeneralController extends ApplicationController {
  static targets = ['deleteDictionaryModal', 'dictionaryPages', 'dictionaryDate', 'confirmDeleteDictionaryBtn'];

  // For displaying the confirmation modal after clicking delete dictionary button
  showDeleteDictionaryConfirmationModal(event){
    var button = event.currentTarget;
    var dictionaryId = button.dataset.dictionaryId;
    var dictionaryPages = button.dataset.dictionaryPages + "-page";
    var dictionaryDate = button.dataset.dictionaryDate;

    this.dictionaryPagesTarget.textContent = dictionaryPages;
    this.dictionaryDateTarget.textContent = dictionaryDate;

    const deleteUrl = '/dictionaries/' + dictionaryId;
    this.confirmDeleteDictionaryBtnTarget.setAttribute('formaction', deleteUrl);
    
    var modal = new bootstrap.Modal(this.deleteDictionaryModalTarget); // // Initialize and show the modal using Bootstrap's Modal class
    modal.show();
  }
}


