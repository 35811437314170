// Reference: https://www.betterstimulus.com/architecture/application-controller.html
// application_controller.js
import { Controller } from "stimulus";

export default class extends Controller {

  // We can use it instead of cropper global variable
  getCropperInstance(){
    let cropperContainer = document.querySelector('#js-cropper');
    let cropperInstance = this.application.getControllerForElementAndIdentifier(
      cropperContainer, "cropper"
    );
    return cropperInstance;
  }

  scrollToCurrentItem(e) {
    scrollToCurrentSelectedItem({ behavior: 'instant' }); // changed behavior from smooth to instant as per client requirement
  }

  // TODO: Generic method implemented, remove this
  // Todo: Currently this is not generic, but it can be made generic
  updateFlashMessages(successRequestsCount, totalRequestsCount) {
    let flashMessageContainer = document.getElementById("js-flash-messages");
    flashMessageContainer.innerHTML += `
      <div data-controller="flash-message">
        <div
          data-flash-message-target="input"
          data-flash-type="success"
          data-message-content="${successRequestsCount} / ${totalRequestsCount} sets have been successfully updated"
        >
        </div>
        <div data-flash-message-target="output"></div>
      </div>
    `;
  }

  // Generic method to add flash messages dynamically in js
  addFlashMessage(message, message_type = 'error') {
    showFlashMessage(message, message_type)
  }

  refereshIcons(){
    setTimeout(function() {
      feather.replace();
    }, 100)
  }
}
